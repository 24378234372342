import React from "react";
import PitchesOverview from "../components/PitchesOverview";
import FooterCTA from "../components/FooterCTA";
import CupSection from "../components/CupSection";
import InnovationDayIntroduction from "../components/InnovationDayIntroduction";
import HomepageIntro from "../components/HomepageIntro";

const Homepage = () => {
  return (
    <div>
      <HomepageIntro />
      <InnovationDayIntroduction />
      <CupSection />
      <PitchesOverview />
      <FooterCTA />
    </div>
  );
};

export default Homepage;
