import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Footer from "./components/Footer";
import Pitch from "./pages/Pitch";
import PitchForm from "./pages/PitchForm";
import Login from "./pages/Login";
import Header from "./components/Header";
import Succes from "./pages/Succes";

const Routes = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (authenticated) {
      localStorage.setItem("authenticated", authenticated);
    }
  }, [authenticated]);

  useEffect(() => {
    if (localStorage.getItem("authenticated") === true) {
      setAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        {authenticated || localStorage.getItem("authenticated") ? (
          <>
            <Header />
            <Switch>
              <Route path="/pitches/add">
                <PitchForm />
              </Route>
              <Route path="/pitches/succes">
                <Succes />
              </Route>
              <Route path="/pitches/:id">
                <Pitch />
              </Route>

              <Route path="/">
                <Homepage />
              </Route>
            </Switch>
            <Footer />
          </>
        ) : (
          <Switch>
            <Route path="*">
              <Login setAuthenticated={setAuthenticated} />
            </Route>
          </Switch>
        )}
      </div>
    </Router>
  );
};

export default Routes;
