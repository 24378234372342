import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const PitchesOverview = () => {
  const [pitches, setPitches] = useState();
  useEffect(() => {
    return fetch("https://menukaart.proudnerds.com/.netlify/functions/server", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPitches(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <div className="px-caseoverview" id="pitches" style={{ marginTop: 300 }}>
      <div className="container">
        <div className="mid-container col-12">
          <div className="text-content ">
            <div className="golden-divider"></div>
            <h1
              className="black px-slide-in px-slide-delay-1"
              style={{ marginTop: 25 }}
            >
              Pitches
            </h1>
          </div>
          <div
            className="row"
            style={{
              marginTop: 50,
              display: "flex",
              flexDirection: "space-between",
            }}
          >
            {pitches && pitches.length > 0 ? (
              pitches?.map((pitch, index) => (
                <div
                  key={pitch._id}
                  className={`case-detail px-slide-in px-slide-delay-3 col-xs-12 col-md-6 col-lg-5 ${
                    index % 2 && " offset-md-1 offset-lg-2"
                  }`}
                >
                  <Link
                    className="case-detail-link row"
                    to={pitch._id ? `/pitches/${pitch._id}` : "test"}
                  >
                    <div className="image-placeholder col-12">
                      <picture style={{ width: "100%", overflow: "hidden" }}>
                        <img
                          src={
                            pitch.imageLink
                              ? pitch.imageLink.replace("http://", "https://")
                              : "/images/PN-inno-team.png"
                          }
                          height="400"
                          width="500"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/images/PN-inno-team.png";
                          }}
                          alt="Pitch"
                          style={{ objectFit: "cover" }}
                        />
                      </picture>
                    </div>
                    <div className="px-card   col-xs-12 col-md-8 offset-md-1">
                      <p className="static-client black"></p>
                      <p className="client-name gold">{pitch.name}</p>
                      <h3 className="client-subscript black">
                        {pitch.ideaName}
                      </h3>

                      <span className="link-info">
                        <p className="text-holder">
                          <b>bekijk pitch</b>
                          <img
                            src="/images/arrow-short.svg"
                            alt="Go to pitch"
                          />
                        </p>
                      </span>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p className="text-black">No geen pitches beschikbaar</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PitchesOverview;
