import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
const Header = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <header
      className="App-header px-header"
      style={{
        backgroundColor: pathname === "/pitches/add" ? "#fff" : "#1a1919",
      }}
    >
      <Link to="/">
        <img
          src={
            pathname === "/pitches/add"
              ? "/images/proud-nerds-logo-rgb-zwart.svg"
              : "/images/proud-nerds-logo-rgb-wit.svg"
          }
          className="header-logo logo-white"
          alt="ProudNerds logo"
        />
      </Link>
      <div className="container">
        <div className="row">
          <Link
            to={pathname === "/pitches/add" ? "/" : "/pitches/add"}
            className={`btn-menu contact-phone ${
              pathname === "/pitches/add" ? "row-bg-white" : "row-bg-black"
            }`}
            style={{ padding: 10 }}
          >
            {pathname === "/pitches/add" ? "TERUG NAAR HOME" : "PITCH JE IDEE"}
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
